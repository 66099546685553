@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,500,600,700');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a68;
}

#root {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.italic {
  font-style: italic;
  font-weight: 500;
}

.MuiBox-root .pg-viewer-wrapper {
  overflow: auto;
}

.MuiBox-root.pg-wrapper-file-jpg .pg-viewer-wrapper,
.MuiBox-root.pg-wrapper-file-png .pg-viewer-wrapper,
.MuiBox-root.pg-wrapper-file-jpeg .pg-viewer-wrapper {
  overflow: hidden;
}

.pg-viewer-wrapper .pg-viewer #docx {
  display: flex;
}

.pg-viewer-wrapper .pg-viewer .video-container video {
  max-height: 90vh;
  width: auto;
}

.pg-viewer-wrapper .pg-viewer .pdf-viewer {
  text-align: center;
}

.pg-viewer-wrapper {
  overflow: auto;
}
